const main = document.querySelector('#main')
const inquiryForm = document.querySelectorAll('.js-inquiryForm')
const legalModals = document.querySelectorAll('.js-legalModal')
const legal = document.querySelectorAll('.js-legalModalContent')[0]

/**
 * handle opening modal on click
 * @param event
 * @returns {void}
 */
function handleOpenModal(event) {
  if (event.target.classList.contains('js-openLegalModal')) {

    inquiryForm.forEach((modal) => {
      modal.classList.remove('is-active')
      modal.setAttribute('aria-hidden', 'true')
    })
    legalModals.forEach((modal) => {
      modal.classList.add('is-active')
      modal.setAttribute('aria-hidden', 'false')

      if (!main.classList.contains('has-modal')) {
        main.classList.add('has-modal')
      }
    })
  }
}

/**
 * Close modal on action
 * @param event
 * @returns {void}
 */
function handleCloseModal(event) {
  if (event.target.classList.contains('js-closeLegalModal')) {

    inquiryForm.forEach((modal) => {
      modal.classList.add('is-active')
      modal.setAttribute('aria-hidden', 'false')
    })

    legalModals.forEach((modal) => {
      modal.classList.remove('is-active')
      modal.setAttribute('aria-hidden', 'true')
    })
  }
}

// events
document.addEventListener('click', handleOpenModal)
document.addEventListener('click', handleCloseModal)
